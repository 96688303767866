<template>
  <div>
    <HeroInner descrition="Gestão de Empresa" class="mt-70" />

    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon name="fal fa-user-tie title-icon" size="32" colorFont="#C3C6D5" />
            <h2>Gestão de empresas</h2>
          </div>
          <div>
            <InputText type="search" place-holder="Buscar..." :showLoader="false" v-model="search" />
          </div>
        </div>
        <div>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="companies"
            :pagination.sync="pagination"
            :search="search"
            locale="pt_BR"
            :expand="false"
            :custom-filter="customFilter"
            :rows-per-page-items="[25, 50, 100, {'text': '$vuetify.dataIterator.rowsPerPageAll', 'value':-1}]"
            select-all
            item-key="id"
            class="text-xs-left"
          >
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr :active="props.selected" @click="showDetails(props)">
                <td>{{ props.item.name }}</td>
                <!-- <td>{{ props.item.entity.name }}</td>
                <td>{{ props.item.entity.cnpj | cnpj }}</td>
                <td>{{ props.item.entity.creationDate | date }}</td>
                <td class="text-xs-right">{{ props.item.employeeQuantity }}</td>-->
              </tr>
            </template>
            <template v-slot:expand="props">
              <v-card flat>
                <div>
                  <table class="simple-table left">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Razão Social</th>
                        <th>Data de criação</th>
                        <th>Quantidade de usuários</th>
                        <th>Cnpj</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="company in props.item.companies" :key="company.id">
                        <td>{{ company.entity.name }}</td>
                        <td>{{ company.entity.socialReason }}</td>
                        <td>{{ company.entity.creationDate | date }}</td>
                        <td>{{ company.amountOfActiveUsers }}</td>
                        <td>{{ company.entity.cnpj | cnpj }}</td>
                        <td class="text-xs-right pr-1">
                          <button
                            class="btn-line small mr-2"
                            @click="editCompany(company)"
                            title="Editar"
                          >
                            <i class="fal fa-pencil"></i>
                          </button>
                          <button
                            class="btn-line small mr-2"
                            title="Inativar"
                            v-if="company.entity.entityStatusId == 1"
                            @click="setEntityStatus(company.entity, 2)"
                          >
                            <i class="fal fa-ban"></i>
                          </button>
                          <button
                            class="btn-line btn-primary small"
                            title="Ativar"
                            v-if="company.entity.entityStatusId == 2"
                            @click="setEntityStatus(company.entity, 1)"
                          >
                            <i class="fal fa-power-off"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-card>
            </template>
          </v-data-table>
        </div>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import CompanyGroupService from "@/scripts/services/companyGroup.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import EntityService from "@/scripts/services/entity.service";
import { Routes } from "@/scripts/models/enums/routes.enum";

export default {
  components: {
    HeroInner
  },
  data: () => ({
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    expand: false,

    headers: [
      { text: "Grupo Econômico", value: "name", align: "left" }
      //   { text: "Nome Empresa", value: "entity.name", align: "left" },
      //   { text: "CNPJ", value: "entity.cnpj", align: "left" },
      //   { text: "Data criação", value: "entity.creationDate", align: "left" },
      //   {
      //     text: "Quantidade de usuários",
      //     value: "employeeQuantity",
      //     align: "left"
      //   }
    ],
    companies: [],
    companyGroupService: new CompanyGroupService(),
    entityService: new EntityService()
  }),
  created() {
    this.loadCompaniesInformation();
  },
  methods: {
    editCompany(company) {
      this.$router.push({
        name: Routes.app.CompanyProfile,
        query: { companyId: company.id }
      });
    },
    setEntityStatus(entity, entityStatusId) {
      this.entityService.setEntityStatus(entity.id, entityStatusId).then(
        function(data) {
          if (data.success) {
            entity.entityStatusId = entityStatusId;
          }
        }.bind(this)
      );
    },
    customFilter(companyGroups, search) {
      search = search.toLowerCase();
      return companyGroups.filter(function(companyGroup) {
        return (
          companyGroup.name.toLowerCase().indexOf(search) !== -1 ||
          companyGroup.companies.some(function(company) {
            return (
              company.entity.name.toLowerCase().indexOf(search) !== -1 ||
              company.entity.socialReason.toLowerCase().indexOf(search) !==
                -1 ||
              company.entity.cnpj.toLowerCase().indexOf(
                search
                  .replace(/\./g, "")
                  .replace(/\-/g, "")
                  .replace(/\//g, "")
                  .replace(" ", "")
              ) !== -1
            );
          })
        );
      });
    },
    loadCompaniesInformation() {
      return this.companyGroupService
        .loadCompaniesInformation()
        .then(this.onCompaniesLoaded);
    },
    onCompaniesLoaded(companies) {
      this.companies = companies;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    showDetails(props) {
      props.expanded = !props.expanded;
    }
  },
  filters: {
    cnpj: function(cnpj) {
      return new FormatterHelper().formatCnpj(cnpj);
    },
    cpf: function(cpf) {
      return new FormatterHelper().formatCpf(cpf);
    },
    date: function(date) {
      return new FormatterHelper().formatDate(date);
    },
    postalCode: function(postalCode) {
      return new FormatterHelper().formatPostalCode(postalCode);
    }
  }
};
</script>

<style>
</style> 